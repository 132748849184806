'use client';

import React, {useEffect} from "react";
import {useStickyFooterAdStore, useStickyHeaderStore} from "@/lib/stores/layout";
import {useThrottle, useWindowScroll} from "@uidotdev/usehooks";

export default function Utils() {
  return (
    <>
      <Utils.StickyHeader/>
      <Utils.StickyFooterAd/>
    </>
  );
}

Utils.StickyHeader = function () {
  const stickyState = useStickyHeaderStore();
  const [{y}] = ('undefined' !== typeof window) ? useWindowScroll() : [{y: 0}];

  let offset = 0;
  if (typeof window !== 'undefined') {
    const welcomerAds = document.getElementsByClassName('genecyAd-welcomer-eCont');
    if (welcomerAds.length > 0) {
      offset = welcomerAds[0].offsetHeight;
    }

    const wallpaperAds = document.getElementsByClassName('genecyAd-wallpaper-eCont');
    if (wallpaperAds.length > 0) {
      offset += wallpaperAds[0].offsetHeight;
    }

    const momSkinAds = document.getElementsByClassName('mom-skin__header');
    if (momSkinAds.length > 0) {
      offset += momSkinAds[0].offsetHeight;
    }
  }

  const shouldHeaderSticky = y > 150 + offset;
  /*const width = useThrottledWindowWidth();*/

  /*useEffect(() => {
    stickyState.setSticky(shouldHeaderSticky && width >= 768);
  }, [shouldHeaderSticky, width]);*/

  useEffect(() => {
    stickyState.setSticky(shouldHeaderSticky);
  }, [shouldHeaderSticky]);
};

Utils.StickyFooterAd = function () {
  const setVisibility = useStickyFooterAdStore(state => state.setVisibility);
  const [{y}] = ('undefined' !== typeof window) ? useWindowScroll() : [{y: 0}];
  const throttledWindowScrollTop = useThrottle(y, 100);

  // Toggle sticky footer ad
  useEffect(() => {
    setVisibility(throttledWindowScrollTop > 300);
  }, [throttledWindowScrollTop]);
};
